import React, { Component } from 'react'


import { GetBatismoDetails } from '../../../../services';
import swal from 'sweetalert';
import Moment from 'moment';

import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { NotificationManager } from 'react-notifications';

import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTable,
    CTableBody,
    CTableCaption,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CSpinner
  } from '@coreui/react'


  import CIcon from '@coreui/icons-react'
  import {
    cilPencil,cilTrash,
  } from '@coreui/icons'


 import { getCookie } from '../../../../../function';
 let nome = getCookie("nome");
 let role = getCookie("role");

export default class List extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
  };

    constructor(props) {
        super(props);
        this.state = {
            getList: [],
            isLoaded:false,
            totalBatizandos: 0, // Estado para a contagem total de batizandos
            filtroData: '2024-12-08' // Estado para a data de filtro
        }
    }
   
   async componentDidMount() {
      this.getBatismo('2024-12-08');
    }    

    handleChange(e) {
      this.setState({ [e.target.name]: e.target.value })  
      this.getBatismo(e.target.value); 
    }

    async getBatismo(data) {
      //this.setState({ isLoaded: true });
      let list = await GetBatismoDetails.getAllBatismoList();
      console.log(list);
      if (list) {
      
                
        const dataFiltrada = data
        ? list.data.filter((item) => item.data_batismo === data)
        : list.data;
    
        //this.setState({ getList: dataFiltrada });
        this.setState({ getList: dataFiltrada.filter(item => item.supervisores  === nome || role === 'admin')})
        this.setState({ totalBatizandos: dataFiltrada.filter(item => item.supervisores  === nome || role === 'admin').length }); // Defina a contagem total
        //this.setState({ isLoaded: false });
      } else {
        this.props.history.push({ pathname: `/auth/login` });
      }
    }
    


    async handlDeleteById(id,cargo) {
      swal({
          title: "Excluir batizando " + cargo + " ?",     
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
          .then(async (success) => {
              if (success) {
                  this.setState({ isLoaded: true })
                  let value = await GetBatismoDetails.getDeleteBatismoList(id);
                  if (value) {                    
                    this.getBatismo();
                  }
                  this.setState({ isLoaded: false })
              }
          });
   }   


   handlEditRow(row,nome,email,telefone,est_c,idade,celula,lider,supervisores,ne,turma,curso, inscricao, reuniao) {      
    this.props.history.push({ pathname: `/admin/batismo/edit/${row}`,state: row,nome:nome ,email: email,telefone: telefone,est_c: est_c,idade: idade,celula: celula,
      lider: lider,supervisores: supervisores,ne: ne,turma: turma, curso: curso, inscricao: inscricao, reuniao: reuniao})
  }
 
    render() {        
        let self = this.state.getList
        let isLoaded = this.state.isLoaded

        return (
            
          <CRow>          
          {/* <CCol xs={12}> */}
          <CCard className="mb-4" >
          <CCardHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              Visualização da Turma de Batismo - Total: {this.state.totalBatizandos}
            </div>
            <div>
              <label htmlFor="filtroData" style={{ marginRight: '10px' }}>Filtrar por Data:</label>
              <input
                type="date"
                value={this.state.filtroData || ''}
                name="filtroData"
                onChange={(e) => this.handleChange(e)}
              />
              {/* <button onClick={this.getBatismo}>Filtrar</button> */}
            </div>
          </CCardHeader>
          <CCardBody>
               <CTable align="middle" className="mb-0 border card-body-table table" hover responsive small bordered style={{fontFamily: "Arial"}}>
                <CTableHead color="dark" >
                  <CTableRow color="dark">  
                    <CTableHeaderCell className="text-center">Alterar</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Deletar</CTableHeaderCell>                 
                    <CTableHeaderCell >Nome</CTableHeaderCell>                
                    <CTableHeaderCell >Email</CTableHeaderCell>
                    <CTableHeaderCell >Telefone</CTableHeaderCell>
                    <CTableHeaderCell >Estado Civil</CTableHeaderCell>
                    {/* <CTableHeaderCell >Idade</CTableHeaderCell> */}
                    {/* <CTableHeaderCell >Célula</CTableHeaderCell> */}
                    {/* <CTableHeaderCell >Líder</CTableHeaderCell> */}
                    <CTableHeaderCell >Supervisores</CTableHeaderCell>
                    <CTableHeaderCell >Surdo</CTableHeaderCell>                    
                    <CTableHeaderCell >Turma</CTableHeaderCell>
                    <CTableHeaderCell >Fez o Curso</CTableHeaderCell>
                    <CTableHeaderCell >Fez a Inscrição</CTableHeaderCell>                    
                    <CTableHeaderCell >Participou da Reunião</CTableHeaderCell>                  
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {self.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index} color="success" style={{color: "green"}}>                     
                      
                      <CTableDataCell className="text-center" onClick={(e) => this.handlEditRow(item,item.nome,item.email,
                                                                                                item.telefone,item.est_c,
                                                                                                item.idade,item.celula,
                                                                                                item.lider,item.supervisores,
                                                                                                item.ne,item.turma
                                                                                                )}>                        
                      <CIcon  icon={cilPencil} title='Alterar'/>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">  
                      {role === 'admin' &&                      
                      <CIcon icon={cilTrash} title='Deletar' onClick={(e) => this.handlDeleteById(item.id,item.nome)}/> 
                      }
                      </CTableDataCell>
                      
                      <CTableDataCell>{item.nome}</CTableDataCell>                      
                      <CTableDataCell>{item.email}</CTableDataCell> 
                      <CTableDataCell>{item.telefone}</CTableDataCell> 
                      <CTableDataCell>{item.est_c}</CTableDataCell> 
                      {/* <CTableDataCell>{item.idade}</CTableDataCell> */}
                      {/* <CTableDataCell>{item.celula}</CTableDataCell> */}
                      {/* <CTableDataCell>{item.lider}</CTableDataCell> */}
                      <CTableDataCell>{item.supervisores}</CTableDataCell> 
                      <CTableDataCell>{item.ne =='Sim' ? <span className="text-danger">SIM</span> : <span className="text-success">NÃO</span>}</CTableDataCell>
                      <CTableDataCell>{item.turma}</CTableDataCell> 
                      <CTableDataCell>{item.curso =='Sim' ? <span className="text-success">SIM</span> : <span className="text-danger">NÃO</span>}</CTableDataCell> 
                      <CTableDataCell>{item.inscricao =='Sim' ? <span className="text-success">SIM</span> : <span className="text-danger">NÃO</span>}</CTableDataCell> 
                      <CTableDataCell>{item.reuniao =='Sim' ? <span className="text-success">SIM</span> : <span className="text-danger">NÃO</span>}</CTableDataCell>                       
                     
                    </CTableRow>
                  ))}

                      
                  
                </CTableBody>               
              </CTable>
               <CRow>
              </CRow>             
              
              
              </CCardBody>
            </CCard> 
            {
                isLoaded ? <CSpinner color="success" style={{ flexDirection: 'row', marginLeft: '50%',alignItems:"center" }}  /> : ''
            }
            
            {/* </CCol > */}
            
            </CRow>

        )
    }
}
